import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // (查詢)購物車列表
    getCartList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/cart', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // // (新增)購物車
    // setCartCreate(ctx, resolveData) {
    //   return new Promise((resolve, reject) => {
    //     axios('/admin/cart', {
    //       method: 'POST',
    //       headers: {
    //         'Access-Token': useJwt.getToken(),
    //       },
    //       data: {
    //         ...resolveData,
    //       },
    //     })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },

    // // (更新)購物車
    // setCartUpdate(ctx, resolveData) {
    //   return new Promise((resolve, reject) => {
    //     axios(`/admin/cart/${resolveData.cart_id}`, {
    //       method: 'PATCH',
    //       headers: {
    //         'Access-Token': useJwt.getToken(),
    //       },
    //       data: {
    //         ...resolveData.data,
    //       },
    //     })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },

    // (刪除)購物車
    setCartDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/cart/${resolveData.cart_id}`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)會員購物車列表
    getCustomerCartList(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/customer/${resolveData.customer_id}/cart`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
